import { Box, Grid, useMediaQuery } from '@mui/material'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Cookies from 'js-cookie'
import React, { useLayoutEffect } from 'react'
import { Footer, Header, SEO, useI18next } from '../../components'
import Title from '../../components/Title'

export default (props) => {
  const {} = useTranslation()
  const { t, languages, changeLanguage, language } = useI18next()
  const pageLocale = JSON.parse(props.data.locales.edges[0].node.data)
  const seoLocale = JSON.parse(props.data.seoLocale.data)
  const isPc = useMediaQuery('@media(min-width: 800px)')
  const { navigate } = useI18next()
  const country = props?.location?.state?.country || 'UAE'
  const systemLang = Cookies.get('EXPRESS_LANG')

  useLayoutEffect(() => {
    document.querySelector('.banner')?.scrollIntoView({})
  }, [])

  const BreadCrumb = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '40px',
          div: {
            color: 'rgba(0, 4, 23, .5)',
            fontSize: '16px',
            lineHeight: '28px',
            '&:last-child': {
              color: 'rgba(0, 4, 23, .8)',
            },
          },
          '.link': {
            cursor: 'pointer',
            '&:hover': {
              color: '#095ef7',
            },
          },
          span: {
            marginInlineStart: '12px',
            marginInlineEnd: '12px',
          },
        }}
      >
        <div
          className='link'
          onClick={() => {
            navigate('/')
          }}
        >
          {pageLocale.Home}
        </div>
        <span>/</span>
        <div
          className='link'
          onClick={() => {
            navigate('/service/logisticsProducts')
          }}
        >
          {pageLocale.LogisticsProducts}
        </div>
        <span>/</span>
        <div>{pageLocale.LogisticsProductsDetail[country].BreadCrumbTitle}</div>
      </Box>
    )
  }

  const ProductList = () => {
    return (
      <Grid
        container
        spacing={{ xs: '20px', sm: '20px', md: '20px', lg: '20px', xl: '40px' }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        sx={{
          marginBottom: ['0px', '70px'],
        }}
      >
        {pageLocale.LogisticsProductsDetail[country].ProductList.map((item, index) => {
          return (
            <Grid item xs={isPc ? 4 : 12} sm={isPc ? 4 : 12} md={isPc ? 4 : 12} key={index}>
              <Box
                sx={{
                  background: 'linear-gradient(#F6F6FB, #FFFFFF)',
                  boxShadow: '0 4px 12px 0 rgba(2, 7, 122, 0.1)',
                  padding: ['20px', '20px', '36px 8px', '36px 24px'],
                  borderRadius: '4px',
                  '.top-contain': {
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: ['20px', '20px', '12px'],
                    '.title-img': {
                      width: '40px',
                      height: '40px',
                      marginInlineEnd: '12px',
                    },
                    '.text': {
                      color: '#000',
                      fontSize: ['18px', '18px', '22px'],
                      fontWeight: '600',
                      lineHeight: '40px',
                    },
                  },
                  '.center-contain': {
                    color: '#000417',
                    fontSize: '17px',
                    fontWeight: '600',
                    lineHeight: ['22px', '22px', '28px'],
                    marginBottom: ['14px', '14px', '12px'],
                  },
                  '.bottom-contain': {
                    display: 'flex',
                    alignItems: 'center',
                    '.bottom-contain-item': {
                      display: 'flex',
                      alignItems: 'center',
                      '.country-text': {
                        color: '#5B5C73',
                        fontSize: '14px',
                        lineHeight: '18px',
                        marginInlineStart: '8px',
                      },
                      '.ariport-img': {
                        height: '10px',
                        width: '59px',
                        marginInlineStart: '20px',
                        marginInlineEnd: '20px',
                        transform: systemLang === 'ar_SA' ? 'rotate(180deg)' : 'rotate(0deg)',
                      },
                      '.country-img': {
                        height: '30px',
                        width: '38px',
                        margin: '-4px',
                      },
                    },
                  },
                }}
              >
                <div className='top-contain'>
                  {index === 2 ? (
                    <StaticImage
                      src='../../../static/images/logisticsProduct/productCar.png'
                      alt='About iMile Delivery'
                      objectFit='cover'
                      loading='lazy'
                      placeholder='blurred'
                      className='title-img'
                    />
                  ) : (
                    <StaticImage
                      src='../../../static/images/logisticsProduct/productAirport.png'
                      alt='About iMile Delivery'
                      objectFit='cover'
                      loading='lazy'
                      placeholder='blurred'
                      className='title-img'
                    />
                  )}
                  <div className='text'>{item.title}</div>
                </div>
                {item.subTitle && <div className='center-contain'>{item.subTitle}</div>}
                <div className='bottom-contain'>
                  {item.line.map((_item, _index) => {
                    return (
                      <div key={_index} className='bottom-contain-item'>
                        <img
                          src={
                            require(`../../../static/images/logisticsProduct/Detail_${_item}.png`)
                              .default
                          }
                          alt=''
                          className='country-img'
                        />
                        <div className='country-text'>{item.lineName[_index]}</div>
                        {item.line.length === 2 && _index === 0 && (
                          <StaticImage
                            src='../../../static/images/logisticsProduct/ariport.png'
                            alt='About iMile Delivery'
                            objectFit='cover'
                            loading='lazy'
                            placeholder='blurred'
                            className='ariport-img'
                          />
                        )}
                      </div>
                    )
                  })}
                </div>
              </Box>
            </Grid>
          )
        })}
      </Grid>
    )
  }

  const ProductIntro = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: isPc ? 'row' : 'column',
          backgroundColor: isPc ? '#F6F7FA' : '#fff',
          borderRadius: isPc ? '8px' : '0px',
          '.left-area': {
            position: 'relative',
            marginInlineEnd: isPc ? '20px' : '0',
            marginBottom: isPc ? '0' : '20px',
            width: isPc ? '50%' : 'auto',
            minWidth: isPc ? '496px' : 'auto',
            '.intro-img': {
              width: ['100%'],
              borderEndStartRadius: '10px',
              borderStartStartRadius: '10px',
            },
            '.intro-mobile-img': {
              width: ['calc(100% + 32px)', 'calc(100% + 80px)'],
              margin: ['0px -16px', '0px -40px'],
            },
            '.text': {
              position: 'absolute',
              bottom: '0',
              left: '0',
              width: isPc ? '100%' : ['calc(100% + 32px)', 'calc(100% + 80px)'],
              margin: isPc ? '0' : ['0px -16px', '0px -40px'],
              backgroundColor: 'rgba(0, 4, 23, 0.2)',
              padding: ['20px 16px', '24px'],
              borderRadius: '0 0 10px 10px',
              div: {
                lineHeight: ['22px', '36px', '28px'],
                fontSize: ['14px', '20px', '16px'],
                fontWeight: '600',
                marginBottom: '0',
                color: '#fff',
              },
            },
          },
          '.right-area': {
            flex: '1',
            width: isPc ? 'auto' : ['100%', 'calc(100% + 48px)'],
            paddingInlineEnd: isPc ? '20px' : '0px',
            '.right-area-item': {
              display: 'flex',
              alignItems: 'center',
              padding: [
                '16px',
                '16px',
                ['JOR', 'OMN', 'KWT', 'QAT', 'BHR'].includes(country) ? '8px 20px' : '14px 20px',
                '16px 20px',
              ],
              marginBottom: ['20px', '20px', '0', '0', '10px'],
              backgroundColor: ['#F6F7FA'],
              borderRadius: '8px',
              '&:last-child': {
                marginBottom: '0px',
              },
              '.circle-img': {
                width: ['30px', '40px'],
                height: ['30px', '40px'],
                marginInlineEnd: ['10px', '20px'],
              },
              '.text': {
                flex: '1',
                color: '#000417',
                fontSize: ['14px', '16px', '15px'],
                lineHeight: ['20px', '28px', '28px'],
              },
            },
          },
        }}
      >
        <div className='left-area'>
          {isPc ? (
            <img
              src='https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/ProductIntro.png'
              alt='About iMile Delivery'
              className='intro-img'
            />
          ) : (
            <img
              src='https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/ProductIntro-mobile.png'
              alt='About iMile Delivery'
              className='intro-mobile-img'
            />
          )}
          {/* {isPc ? (
            <StaticImage
              src='../../../static/images/logisticsProduct/ProductIntro.png'
              alt='About iMile Delivery'
              objectFit='cover'
              loading='lazy'
              placeholder='blurred'
              className='intro-img'
            />
          ) : (
            <StaticImage
              src='../../../static/images/logisticsProduct/ProductIntro-mobile.png'
              alt='About iMile Delivery'
              objectFit='cover'
              loading='lazy'
              placeholder='blurred'
              className='intro-mobile-img'
            />
          )} */}
          <div className='text'>
            <div>{pageLocale.LogisticsProductsDetail[country].ProductIntro.title}</div>
          </div>
        </div>
        <div className='right-area'>
          {pageLocale.LogisticsProductsDetail[country].ProductIntro.advantages.map((item) => {
            return (
              <div key={item} className='right-area-item'>
                <StaticImage
                  src='../../../static/images/logisticsProduct/detailIcon.png'
                  alt='About iMile Delivery'
                  objectFit='cover'
                  loading='lazy'
                  placeholder='blurred'
                  className='circle-img'
                />
                <div className='text'>{item}</div>
              </div>
            )
          })}
        </div>
      </Box>
    )
  }

  const ProductDetails = () => {
    return (
      <Box
        sx={{
          '.productDetail-title': {
            backgroundColor: '#151741',
            color: '#fff',
            fontSize: '16px',
            display: 'flex',
            alignItems: 'center',
            padding: '24px 40px',
            borderRadius: '8px 8px 0 0',
            div: {
              color: '#fff',
              fontSize: '16px',
              lineHeight: '28px',
              ':nth-child(1)': {
                width: '30%',
                marginInlineEnd: '20px',
              },
              ':nth-child(2)': {
                flex: '1',
              },
            },
          },
          '.productDetail-content': {
            backgroundColor: 'rgba(6, 44, 255, 0.04)',
            '.content-item': {
              display: 'flex',
              alignItems: 'center',
              padding: '24px 40px',
              borderBottom: '0.5px solid #E8EAF6',
              '&:last-child': {
                borderBottom: 'none',
              },
              '.content-item-key': {
                width: '30%',
                marginInlineEnd: '20px',
                fontSize: '16px',
                color: '#000417',
                lineHeight: '28px',
              },
              '.content-item-value': {
                flex: '1',
                '.sub-item-wapper': {
                  marginBottom: '16px',
                  '&:last-child': {
                    marginBottom: '0px',
                  },
                  '.sub-item-wapper-content': {
                    color: '#000417',
                    fontSize: '16px',
                    lineHeight: '28px',
                  },
                  '.sub-item-wapper-contentDesc': {
                    div: {
                      color: '#7c7e92',
                      fontSize: '16px',
                      lineHeight: '28px',
                      marginBottom: '0px',
                    },
                  },
                },
              },
            },
          },
        }}
      >
        <div className='productDetail-title'>
          <div>{pageLocale.ProductCategoryTitle}</div>
          <div>{pageLocale.Message}</div>
        </div>
        <div className='productDetail-content'>
          {Object.keys(pageLocale.LogisticsProductsDetail[country].ProductDetails).map((key) => {
            return (
              <div key={key} className='content-item'>
                <div className='content-item-key'>{key}</div>
                <div className='content-item-value'>
                  {pageLocale.LogisticsProductsDetail[country].ProductDetails[key].message.map(
                    (item) => {
                      return (
                        <div className='sub-item-wapper' key={item.content}>
                          {/<[^>]+>/g.test(item.content) ? (
                            <div
                              className='sub-item-wapper-content'
                              dangerouslySetInnerHTML={{ __html: item.content }}
                            ></div>
                          ) : (
                            <div className='sub-item-wapper-content'>{item.content}</div>
                          )}
                          {item.contentDesc && !!item.contentDesc.length && (
                            <div className='sub-item-wapper-contentDesc'>
                              {item.contentDesc.map((_item) => {
                                return (
                                  <>
                                    {/<[^>]+>/g.test(_item) ? (
                                      <div
                                        key={_item}
                                        dangerouslySetInnerHTML={{ __html: _item }}
                                      ></div>
                                    ) : (
                                      <div key={_item}>{_item}</div>
                                    )}
                                  </>
                                )
                              })}
                            </div>
                          )}
                        </div>
                      )
                    },
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </Box>
    )
  }

  const ProductMobileDetails = () => {
    return (
      <Box
        sx={{
          '.productDetail-content': {
            backgroundColor: '#F6F7FA',
            margin: ['0 -16px', '0 -40px'],
            padding: '30px 0',
            borderRadius: '20px',
            '.content-item': {
              padding: ['0 16px', '0 16px', '24px 40px'],
              marginBottom: '30px',
              '&:last-child': {
                marginBottom: '0px',
              },
              '.content-item-key': {
                fontSize: '16px',
                color: '#000417',
                lineHeight: '24px',
                fontWeight: '600',
                marginBottom: '8px',
              },
              '.content-item-value': {
                '.sub-item-wapper': {
                  marginBottom: '16px',
                  '&:last-child': {
                    marginBottom: '0px',
                  },
                  '.sub-item-wapper-content': {
                    color: '#5B5C73',
                    fontSize: '14px',
                    lineHeight: '22px',
                  },
                  '.sub-item-wapper-contentDesc': {
                    div: {
                      color: '#5B5C73',
                      fontSize: '14px',
                      lineHeight: '22px',
                      marginBottom: '0px',
                    },
                  },
                },
              },
            },
          },
        }}
      >
        <div className='productDetail-content'>
          {Object.keys(pageLocale.LogisticsProductsDetail[country].ProductDetails).map((key) => {
            return (
              <div key={key} className='content-item'>
                <div className='content-item-key'>{key}</div>
                <div className='content-item-value'>
                  {pageLocale.LogisticsProductsDetail[country].ProductDetails[key].message.map(
                    (item) => {
                      return (
                        <div className='sub-item-wapper' key={item.content}>
                          {/<[^>]+>/g.test(item.content) ? (
                            <div
                              className='sub-item-wapper-content'
                              style={{
                                fontWeight:
                                  item.contentDesc && !!item.contentDesc.length ? '600' : '400',
                              }}
                              dangerouslySetInnerHTML={{ __html: item.content }}
                            ></div>
                          ) : (
                            <div
                              className='sub-item-wapper-content'
                              style={{
                                fontWeight:
                                  item.contentDesc && !!item.contentDesc.length ? '600' : '400',
                              }}
                            >
                              {item.content}
                            </div>
                          )}
                          {item.contentDesc && !!item.contentDesc.length && (
                            <div className='sub-item-wapper-contentDesc'>
                              {item.contentDesc.map((_item) => {
                                return (
                                  <>
                                    {/<[^>]+>/g.test(_item) ? (
                                      <div
                                        key={_item}
                                        dangerouslySetInnerHTML={{ __html: _item }}
                                      ></div>
                                    ) : (
                                      <div key={_item}>{_item}</div>
                                    )}
                                  </>
                                )
                              })}
                            </div>
                          )}
                        </div>
                      )
                    },
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </Box>
    )
  }

  const ServicesValue = () => {
    return (
      <Box
        sx={{
          textAlign: 'center',
          '.sub-services': {
            color: ['#5B5C73', '#000417'],
            fontSize: ['16px'],
            marginBottom: ['60px', '120px'],
          },
          '.qrcode-box': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: isPc ? 'row' : 'column',
            img: {
              marginBottom: ['10px', '20px'],
              width: ['160px', '190px'],
              height: ['160px', '190px'],
              boxShadow: '0 0 5px 0 #DBDCED',
              borderRadius: '10px',
              padding: '8px',
            },
            '.wx-img': {
              marginInlineEnd: isPc ? '50px' : '0',
            },
            '.whats-app-img': {
              marginInlineStart: isPc ? '50px' : '0',
            },
            '.contact': {
              color: '#000417',
              fontWeight: '500',
              fontSize: ['16px', '20px'],
              marginBottom: ['60px', '120px'],
            },
          },
        }}
      >
        <p className='sub-services'>{pageLocale.LogisticsProductsDetail[country].ServicesValue}</p>
        <div className='qrcode-box'>
          <div className='wx-img'>
            <img src={require(`../../../static/images/countryQrCode/CHN.png`).default} alt='' />
            <div className='contact'>{pageLocale.wx}</div>
          </div>
          <div className='whats-app-img'>
            {isPc ? (
              <img
                src={
                  require(`../../../static/images/countryQrCode/${country}-WhatsApp.png`).default
                }
                alt=''
              />
            ) : (
              <img
                src={
                  require(`../../../static/images/countryQrCode/${country}-WhatsApp.png`).default
                }
                alt=''
              />
            )}
            <div className='contact'>WhatsApp</div>
          </div>
        </div>
      </Box>
    )
  }

  // 国家不一样，title样式不一样
  const style = {
    UAE: isPc
      ? {
          top: ['75%', '25%', '32%'],
          left: ['16px', '34px', '80px'],
          textAlign: 'left',
        }
      : {
          top: ['20%'],
          right: ['16px'],
          textAlign: 'right',
        },
    KSA: isPc
      ? {
          top: ['75%', '25%', '32%'],
          right: ['16px', '34px', '80px'],
          textAlign: 'right',
        }
      : {
          top: ['20%'],
          right: ['16px'],
          textAlign: 'right',
        },
    MEX: isPc
      ? {
          top: ['75%', '25%', '80%'],
          left: ['16px', '34px', '80px'],
          textAlign: 'left',
        }
      : {
          top: ['20%'],
          left: ['16px'],
          textAlign: 'left',
        },
    JOR: isPc
      ? {
          top: ['75%', '25%', '80%'],
          right: ['16px', '34px', '80px'],
          textAlign: 'right',
        }
      : {
          top: ['20%'],
          right: ['16px'],
          textAlign: 'right',
        },
    OMN: isPc
      ? {
          top: ['75%', '25%', '25%'],
          left: ['16px', '34px', '80px'],
          textAlign: 'left',
        }
      : {
          top: ['20%'],
          right: ['16px'],
          textAlign: 'right',
        },
    KWT: isPc
      ? {
          top: ['75%', '25%', '25%'],
          left: ['16px', '34px', '80px'],
          textAlign: 'left',
        }
      : {
          top: ['20%'],
          left: ['16px'],
          textAlign: 'left',
        },
    QAT: isPc
      ? {
          top: ['75%', '25%', '25%'],
          left: ['16px', '34px', '80px'],
          textAlign: 'left',
        }
      : {
          top: ['20%'],
          left: ['16px'],
          textAlign: 'left',
        },
    BHR: isPc
      ? {
          top: ['75%', '25%', '80%'],
          right: ['16px', '34px', '80px'],
          textAlign: 'right',
        }
      : {
          top: ['80%'],
          right: ['16px'],
          textAlign: 'right',
        },
  }[country]

  return (
    <>
      <SEO
        title={seoLocale.logisticsProducts.title}
        description={seoLocale.logisticsProducts.description}
        pageLocale={pageLocale}
      />
      <Header
        location={props.location}
        headerLocale={JSON.parse(props.data.headerLocale.data)}
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />

      {/* banner */}
      <Box
        className='banner'
        sx={{
          position: 'relative',
          '.bg-img': {
            width: 1,
            minHeight: ['100vh', '700px'],
            maxHeight: ['100vh', '900px'],
            height: ['100vh', '100vh', '650px', '700px', '90vh'],
            mt: ['0', '55px', '90px'],
          },
        }}
      >
        {isPc ? (
          <>
            {country === 'UAE' && (
              <img
                src='https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/DetailBanner_UAE.png'
                alt='About iMile Delivery'
                className='bg-img'
              />
              //   <StaticImage
              //     src='../../../static/images/logisticsProduct/DetailBanner_UAE.png'
              //     alt='About iMile Delivery'
              //     objectFit='cover'
              //     loading='lazy'
              //     placeholder='blurred'
              //     className='bg-img'
              //   />
            )}
            {country === 'KSA' && (
              <img
                src='https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/DetailBanner_KSA.png'
                alt='About iMile Delivery'
                className='bg-img'
              />
              //   <StaticImage
              //     src='../../../static/images/logisticsProduct/DetailBanner_KSA.png'
              //     alt='About iMile Delivery'
              //     objectFit='cover'
              //     loading='lazy'
              //     placeholder='blurred'
              //     className='bg-img'
              //   />
            )}
            {country === 'MEX' && (
              <img
                src='https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/DetailBanner_MEX.png'
                alt='About iMile Delivery'
                className='bg-img'
              />
              //   <StaticImage
              //     src='../../../static/images/logisticsProduct/DetailBanner_MEX.png'
              //     alt='About iMile Delivery'
              //     objectFit='cover'
              //     loading='lazy'
              //     placeholder='blurred'
              //     className='bg-img'
              //   />
            )}
            {country === 'JOR' && (
              <img
                src='https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/DetailBanner_JOR.png'
                alt='About iMile Delivery'
                className='bg-img'
              />
              //   <StaticImage
              //     src='../../../static/images/logisticsProduct/DetailBanner_JOR.png'
              //     alt='About iMile Delivery'
              //     objectFit='cover'
              //     loading='lazy'
              //     placeholder='blurred'
              //     className='bg-img'
              //   />
            )}
            {country === 'OMN' && (
              <img
                src='https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/DetailBanner_OMN.png'
                alt='About iMile Delivery'
                className='bg-img'
              />
              //   <StaticImage
              //     src='../../../static/images/logisticsProduct/DetailBanner_OMN.png'
              //     alt='About iMile Delivery'
              //     objectFit='cover'
              //     loading='lazy'
              //     placeholder='blurred'
              //     className='bg-img'
              //   />
            )}
            {country === 'KWT' && (
              <img
                src='https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/DetailBanner_KWT.png'
                alt='About iMile Delivery'
                className='bg-img'
              />
              //   <StaticImage
              //     src='../../../static/images/logisticsProduct/DetailBanner_KWT.png'
              //     alt='About iMile Delivery'
              //     objectFit='cover'
              //     loading='lazy'
              //     placeholder='blurred'
              //     className='bg-img'
              //   />
            )}
            {country === 'QAT' && (
              <img
                src='https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/DetailBanner_QAT.png'
                alt='About iMile Delivery'
                className='bg-img'
              />
              //   <StaticImage
              //     src='../../../static/images/logisticsProduct/DetailBanner_QAT.png'
              //     alt='About iMile Delivery'
              //     objectFit='cover'
              //     loading='lazy'
              //     placeholder='blurred'
              //     className='bg-img'
              //   />
            )}
            {country === 'BHR' && (
              <img
                src='https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/DetailBanner_BHR.png'
                alt='About iMile Delivery'
                className='bg-img'
              />
              //   <StaticImage
              //     src='../../../static/images/logisticsProduct/DetailBanner_BHR.png'
              //     alt='About iMile Delivery'
              //     objectFit='cover'
              //     loading='lazy'
              //     placeholder='blurred'
              //     className='bg-img'
              //   />
            )}
          </>
        ) : (
          <>
            {country === 'UAE' && (
              <img
                src='https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/DetailBanner_BHR.png'
                alt='About iMile Delivery'
                className='bg-img'
              />
              //   <StaticImage
              //     src='../../../static/images/logisticsProduct/DetailBanner_Mobile_UAE.png'
              //     alt='About iMile Delivery'
              //     objectFit='cover'
              //     loading='lazy'
              //     placeholder='blurred'
              //     className='bg-img'
              //   />
            )}
            {country === 'KSA' && (
              <img
                src='https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/DetailBanner_Mobile_KSA.png'
                alt='About iMile Delivery'
                className='bg-img'
              />
              //   <StaticImage
              //     src='../../../static/images/logisticsProduct/DetailBanner_Mobile_KSA.png'
              //     alt='About iMile Delivery'
              //     objectFit='cover'
              //     loading='lazy'
              //     placeholder='blurred'
              //     className='bg-img'
              //   />
            )}
            {country === 'MEX' && (
              <img
                src='https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/DetailBanner_Mobile_MEX.png'
                alt='About iMile Delivery'
                className='bg-img'
              />
              //   <StaticImage
              //     src='../../../static/images/logisticsProduct/DetailBanner_Mobile_MEX.png'
              //     alt='About iMile Delivery'
              //     objectFit='cover'
              //     loading='lazy'
              //     placeholder='blurred'
              //     className='bg-img'
              //   />
            )}
            {country === 'JOR' && (
              <img
                src='https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/DetailBanner_Mobile_JOR.png'
                alt='About iMile Delivery'
                className='bg-img'
              />
              //   <StaticImage
              //     src='../../../static/images/logisticsProduct/DetailBanner_Mobile_JOR.png'
              //     alt='About iMile Delivery'
              //     objectFit='cover'
              //     loading='lazy'
              //     placeholder='blurred'
              //     className='bg-img'
              //   />
            )}
            {country === 'OMN' && (
              <img
                src='https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/DetailBanner_Mobile_OMN.png'
                alt='About iMile Delivery'
                className='bg-img'
              />
              //   <StaticImage
              //     src='../../../static/images/logisticsProduct/DetailBanner_Mobile_OMN.png'
              //     alt='About iMile Delivery'
              //     objectFit='cover'
              //     loading='lazy'
              //     placeholder='blurred'
              //     className='bg-img'
              //   />
            )}
            {country === 'KWT' && (
              <img
                src='https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/DetailBanner_Mobile_KWT.png'
                alt='About iMile Delivery'
                className='bg-img'
              />
              //   <StaticImage
              //     src='../../../static/images/logisticsProduct/DetailBanner_Mobile_KWT.png'
              //     alt='About iMile Delivery'
              //     objectFit='cover'
              //     loading='lazy'
              //     placeholder='blurred'
              //     className='bg-img'
              //   />
            )}
            {country === 'QAT' && (
              <img
                src='https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/DetailBanner_Mobile_QAT.png'
                alt='About iMile Delivery'
                className='bg-img'
              />
              //   <StaticImage
              //     src='../../../static/images/logisticsProduct/DetailBanner_Mobile_QAT.png'
              //     alt='About iMile Delivery'
              //     objectFit='cover'
              //     loading='lazy'
              //     placeholder='blurred'
              //     className='bg-img'
              //   />
            )}
            {country === 'BHR' && (
              <img
                src='https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/DetailBanner_Mobile_BHR.png'
                alt='About iMile Delivery'
                className='bg-img'
              />
              //   <StaticImage
              //     src='../../../static/images/logisticsProduct/DetailBanner_Mobile_BHR.png'
              //     alt='About iMile Delivery'
              //     objectFit='cover'
              //     loading='lazy'
              //     placeholder='blurred'
              //     className='bg-img'
              //   />
            )}
          </>
        )}
        <Box
          sx={{
            position: 'absolute',
            transform: 'translate(0, -40%)',
            color: '#fff',
            ...style,
            '.tit': {
              fontSize: [24, 30, 35, 35, 40],
              fontWeight: 600,
              mb: '10px',
              whiteSpace: 'pre-line',
            },
          }}
        >
          <h1 className='tit'>{pageLocale.LogisticsProductsDetail[country].Slogan}</h1>
        </Box>
      </Box>

      {/* Bread crumb */}
      {isPc && (
        <Box
          sx={{
            px: 'var(--page-px)',
            maxWidth: 'var(--page-width)',
            margin: '0 auto',
            color: '#5b5c73',
          }}
        >
          <BreadCrumb />
        </Box>
      )}

      {/* Products List */}
      <Box
        sx={{
          px: 'var(--page-px)',
          maxWidth: 'var(--page-width)',
          margin: '0 auto',
          color: '#5b5c73',
          h2: {
            width: ['calc(100% + 32px)', '100%'],
            margin: ['0 -16px', '0'],
            fontSize: ['20px', '44px'],
            padding: ['60px 0 40px 0', 'var(--section-spacing-y) 0 var(--section-spacing-y) 0'],
          },
        }}
      >
        <Title title={pageLocale.title1} component='h2' />
        <ProductList />
      </Box>

      {/* Product Introduction & Advantages */}
      <Box
        sx={{
          px: 'var(--page-px)',
          maxWidth: 'var(--page-width)',
          margin: '0 auto',
          color: '#5b5c73',
          h2: {
            width: ['calc(100% + 32px)', '100%'],
            margin: ['0 -16px', '0'],
            fontSize: ['20px', '44px'],
            padding: ['60px 0 40px 0', 'var(--section-spacing-y) 0 var(--section-spacing-y) 0'],
          },
        }}
      >
        <Title title={pageLocale.title2} component='h2' />
        <ProductIntro />
      </Box>

      {/* Product Details */}
      <Box
        sx={{
          px: 'var(--page-px)',
          maxWidth: 'var(--page-width)',
          margin: '0 auto',
          color: '#5b5c73',
          h2: {
            width: ['calc(100% + 32px)', '100%'],
            margin: ['0 -16px', '0'],
            fontSize: ['20px', '44px'],
            padding: ['60px 0 40px 0', 'var(--section-spacing-y) 0 var(--section-spacing-y) 0'],
          },
        }}
      >
        <Title title={pageLocale.title3} component='h2' />
        {isPc ? <ProductDetails /> : <ProductMobileDetails />}
      </Box>

      {/* Value Added Services */}
      <Box
        sx={{
          px: 'var(--page-px)',
          maxWidth: 'var(--page-width)',
          margin: '0 auto',
          color: '#5b5c73',
          h2: {
            width: ['calc(100% + 32px)', '100%'],
            margin: ['0 -16px', '0'],
            fontSize: ['20px', '44px'],
            padding: ['60px 0 40px 0', 'var(--section-spacing-y) 0 var(--section-spacing-y) 0'],
          },
        }}
      >
        <Title title={pageLocale.title4} component='h2' />
        <ServicesValue />
      </Box>

      <Footer
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["logisticsProductsDetail"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
        }
      }
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    seoLocale: locale(ns: { eq: "seo" }, language: { eq: $language }) {
      data
    }
  }
`
